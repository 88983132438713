import './footer.css';
import logo from '../media/cmh-logo.png';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export default function Footer() {
  return (
    <footer>
      <Container className='min-vh100-around'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2 className="About-title" sx={{ mt: 3 }}> Let’s talk. </h2>
            <h3><a>biuro@craftmediahouse.pl</a></h3>
          </Grid>
        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={6} className="wrapDown">
            <h4 className='w100'>Craft Event</h4>
            <h4>Ul. Kruszwicka 26/28, 53-652 Wrocław</h4>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} className="wrapDown">
                <h4 className='w100'><a>Fakebook</a></h4>
                <h4><a>Instagram</a></h4>
              </Grid>
              <Grid item xs={6} className="wrapDown" justifyContent="end" >
                <img src={logo} alt="Logotyp Craft Media House" width="90" sx={{mb: 0}} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
