import './noMatch.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Helmet } from "react-helmet";


export default function noMatch() {

  const BtnCustom = styled(Button)({
    textTransform: 'none',
    borderRadius: '50px',
    display: 'block',
    backgroundColor: 'whitesmoke',
    color: 'black',
    margin: '30px auto',
    '&:hover ': {
      backgroundColor: '#14a800',
      borderColor: '#14a800',
      color: 'white',
      boxShadow: 'none',
    },
    '&:active ': {
      backgroundColor: '#14a800',
      borderColor: '#14a800',
      color: 'white',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  });

  return (
    <>
      <Helmet>
        <title>Craft Media House - Sukces</title>
        <meta name="description" content="Zapomnij o starych zasadach. Możesz mieć najlepszych ludzi.
Właśnie teraz. Właśnie tutaj." />
      </Helmet>

      <div className='block404'>
        <DoneAllIcon sx={{ fontSize: 160, mb: 2, color: "#14a800" }} />
        <h3 sx={{ mb: 0 }} >Dziekuje, wysłaliśmy zgłoszenie</h3>
        <Link to="/" className="btnGoHomeLink">
          <BtnCustom
            sx={{ borderRadius: '50px', py: '10px', mt: 4, color: 'inherit' }}
            disableElevation
            variant="contained"
            className="btnGoHome">
            Strona główna
          </BtnCustom>
        </Link>
      </div>
    </>
  )
}