import './CookiesAccept.css';
import {forwardRef, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import { useCookies, Cookies } from 'react-cookie';

import { styled } from '@mui/material/styles';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomButtonNext = styled(Button)({
  textTransform: 'none',
  borderRadius: '50px',
  padding: '10px 15px',
  backgroundColor: '#14a800',
  borderColor: '#14a800',
  color: 'white',
  fontWeight: '600',
  margin: '10px',
  '&:hover': {
    backgroundColor: 'whitesmoke',
    borderColor: 'whitesmoke',
    color: 'black',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#14a800',
    borderColor: '#14a800',
    color: 'white',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});


export default function AlertDialogSlide() {

  const [cookies, setCookie] = useCookies(['cookiesAccept']);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setCookie('cookiesAccept', 'OK', { path: '/' });
  };

  return (
    <div>
      <Dialog
        className="modulCookies"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <CookieOutlinedIcon sx={{ fontSize: 30, mr: 3 }} />
          {"Ta strona używa plików cookie"}</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText id="alert-dialog-slide-description">
            My i wybrane strony trzecie używamy
            plików cookie (lub podobnych technologii) w celach technicznych,
            aby poprawić i przeanalizować korzystanie z witryny,
            aby wspierać nasze działania marketingowe oraz w innych celach opisanych w
            naszej Polityce dotyczącej plików cookie.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButtonNext type="submit" onClick={handleClose}>Zgadzam się</CustomButtonNext>
        </DialogActions>
      </Dialog>
    </div>
  );
}