import './index.css';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BgAvatar from '../media/BgAvatar';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PhoneMask from '../components/phoneMask';

import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';

// Sand Email
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

//SEO
import { Helmet } from "react-helmet";

const users = [{
  id: 1,
  name: 'Kasia Zaskurska',
  serviceName: 'Skuteczne SEO/SEM',
  src: 'https://craftevent.pl/wp-content/themes/storefront-child/custom-template/image/team/kasia.jpeg',
  about: 'SEO jest obecnie jednym z najważniejszych narzędzi do promowania swojego biznesu w sieci. Dzięki niemu możliwe jest zwiększenie ruchu na stronie internetowej oraz poprawienie wyników na liście wyszukiwań dla konkretnych słów kluczowych'
},
{
  id: 2,
  name: 'Vsevolod Lomov',
  serviceName: 'Sprzedające strony i sklepy internetowe',
  src: 'https://craftevent.pl/wp-content/themes/storefront-child/custom-template/image/team/seva.jpeg',
  about: 'Według użytkowników zakupy w sieci są wygodne i pozwalają zaoszczędzić czas. Klienci mają na wyciągnięcie ręki dostęp do artykułów z całego świata, a dodatkowo łatwo porównać oferty różnych sklepów i wybrać najkorzystniejszą.'
},
{
  id: 3,
  name: 'Kamil Wysoczański',
  serviceName: 'Inspirujące zdjęcia i filmy',
  src: 'https://craftevent.pl/wp-content/themes/storefront-child/custom-template/image/team/kamil.jpeg',
  about: 'Wysokiej jakości zdjęcia, bardziej prawdopodobne, że przyciągną klientów w mediach społecznościowych lub postach promocyjnych.'
},
];

const CustomButtonClose = styled(Button)({
  textTransform: 'none',
  borderRadius: '50px',
  padding: '10px 15px',
  color: '#14a800',
  margin: '10px',
  '&:hover ': {
    color: 'black',
    boxShadow: 'none',
  },
  ':active &': {
    boxShadow: 'none',
    backgroundColor: '#14a800',
    borderColor: '#14a800',
    color: 'white',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});

const CustomButtonNext = styled(Button)({
  textTransform: 'none',
  borderRadius: '50px',
  padding: '10px 15px',
  backgroundColor: '#14a800',
  borderColor: '#14a800',
  color: 'white',
  fontWeight: '600',
  margin: '10px',
  '&:hover': {
    backgroundColor: 'whitesmoke',
    borderColor: 'whitesmoke',
    color: 'black',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#14a800',
    borderColor: '#14a800',
    color: 'white',
  },
  '&:focus': {
    boxShadow: 'none',
  },
});


const ModalSendMail = ({ parentUsersValues }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_tme16sn', 'template_0i5vt0h', form.current, 'J3d1X0Ywg9sieVtLu')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        navigate('/success');
        console.log(error.text);
      });

  };

  const [dataPhone, setDataPhone] = useState(0)
  const dataPhoneValue = (dataPhone) => { setDataPhone(dataPhone) }
  const selectUsers = users.filter(user => parentUsersValues.includes(user.id));

  // const [cookies, setCookie] = useCookies(["favoriteUsersIds"]);
  // setCookie("favoriteUsersIds", selectUsers, { path: '/' });

  const hiddenInput = selectUsers.map((user) => {
    return (
      ' ' + user.serviceName + ' '
    )
  });

  const listSelected = selectUsers.map((user) =>
    <ListItem key={user.id} alignItems="flex-start" sx={{ p: 0 }}>
      <ListItemAvatar>
        <Avatar alt={user.serviceName} src={user.src} />
      </ListItemAvatar>
      <ListItemText
        primary={user.serviceName}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="text.primary" >
              {user.name}
            </Typography>
          </React.Fragment>} />
    </ListItem>
  );

  return (
    <div className='textCenter'>
      <CustomButtonNext
        className="btnFixedNext"
        variant="outlined"
        onClick={handleClickOpen}
        endIcon={<ArrowForwardIcon />}
        disableElevation >
        Dalej
      </CustomButtonNext>
      <Dialog open={open} onClose={handleClose} className="modulSand" >
        <form ref={form} onSubmit={sendEmail}>
          <DialogContent>
            <DialogContentText>
              Zostaw swój numer telefonu, a my skontaktujemy się z Tobą w najbliższym czasie.
            </DialogContentText>
            <Grid item xs={12} sx={{ my: 1 }}>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {listSelected}
              </List>
            </Grid>
            <input name="selected_services" value={hiddenInput} readOnly className="d-none"></input>
            <PhoneMask dataPhone={dataPhoneValue} />

          </DialogContent>
          <DialogActions>
            <CustomButtonClose variant="text" onClick={handleClose}>Zamknąć</CustomButtonClose>
            <CustomButtonNext type="submit" value="Send" disabled={dataPhone.length <= 16} >Wyślij formularz</CustomButtonNext>
            {/* <input type="submit" value="Send" /> */}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};


const ListItems = ({ usersSelectdValues }) => {
  const [setactedValues, setValue] = useState([]);
  const ckeckClickedOrNot = (e) => {
    // does not work in if (not Rendering), so you have to 
    // create an empty array and concat it to an existing one
    const i = [];
    if (setactedValues.includes(e.id)) {
      setactedValues.splice(setactedValues.indexOf(e.id), 1);
    } else {
      setactedValues.push(e.id);
    }
    setValue(i.concat(setactedValues));
    usersSelectdValues(i.concat(setactedValues))
  };



  return (
    users.map((user) =>
      <Grid key={user.id} item xs={12} sm={4} md={4} sx={{ mb: 2 }} onClick={() => ckeckClickedOrNot(user)}>
        <Paper elevation={0} className={`boxPapper ${setactedValues.includes(user.id) ? "active" : ""}`} >
          <Box className="boxAvatarNthChildTwo boxAvatar" >
            <BgAvatar />
            <Avatar
              alt={user.name}
              src={user.src}
              sx={{ width: "100%", height: "100%" }}
            />
          </Box>
          <p className='titleAdd'>{user.serviceName}</p>
          <p className="titleAddSec">{user.about}</p>
        </Paper>
      </Grid>
    )
  )
}


export default function Index() {

  const [parentUsersValues, setParentUsersValues] = useState([])
  const handleParentUsersValues = (e) => {
    setParentUsersValues(e);
  }

  return (
    <>
      <Helmet>
        <title>Craft Media House - Work Marketplace</title>
        <meta name="description" content="Zapomnij o starych zasadach. Możesz mieć najlepszych ludzi.
Właśnie teraz. Właśnie tutaj." />
      </Helmet>
      <Container>
        <div className="primaryBlock min-vh100-center">
          <h4 className="AboutH4">
            Wybierz, co Cię interesuje
          </h4>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ py: 10 }}>
            <ListItems usersSelectdValues={handleParentUsersValues} />
            <Grid item xs={12} >
              <ModalSendMail parentUsersValues={parentUsersValues} />
            </Grid>
          </Grid>
        </div>
      </Container>
    </>

  );
}