import './noMatch.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { Helmet } from "react-helmet";

export default function noMatch() {

  const BtnCustom = styled(Button)({
    textTransform: 'none',
    borderRadius: '50px',
    display: 'block',
    backgroundColor: 'whitesmoke',
    color: 'black',
    margin: '30px auto',
    '&:hover ': {
      backgroundColor: '#14a800',
      borderColor: '#14a800',
      color: 'white',
      boxShadow: 'none',
    },
    '&:active ': {
      backgroundColor: '#14a800',
      borderColor: '#14a800',
      color: 'white',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  });

  return (
    <>
      <Helmet>
        <title>Craft Media House - 404</title>
        <meta name="description" content="Zapomnij o starych zasadach. Możesz mieć najlepszych ludzi.
Właśnie teraz. Właśnie tutaj." />
      </Helmet>

      <div className='block404'>
        <h2>404.</h2>
        <h3>Nie znaleziono strony</h3>
        <p>Możliwą przyczyną może być nieprawidłowy adres URL strony - sprawdź czy nie ma w nim błędów lub strona nie istnieje lub została usunięta.</p>
        <Link to="/" className="btnGoHomeLink">
          <BtnCustom
            sx={{ borderRadius: '50px', py: '10px', mt: 4, color: 'inheriit' }}
            disableElevation
            variant="contained"
            className="btnGoHome">
            {'Na stronę główną'}
          </BtnCustom>
        </Link>
      </div>
    </>
  )
}