import * as React from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useState, useEffect } from 'react'

import { styled } from '@mui/material/styles';


import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="(+48) 000-000-000 (000)"
            definitions={{
                '+48': /[1-1]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite />

    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


const StyledFormControl = styled(FormControl)({
    '& label.Mui-focused': {
      color: '#14a800',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: '2px solid #14a800',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#14a800 ',
      },
      '&:hover fieldset': {
        borderColor: '#14a800',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#14a800',
      },
    },
  });

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#14a800',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: '2px solid #14a800',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#14a800 ',
      },
      '&:hover fieldset': {
        borderColor: '#14a800',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#14a800',
      },
    },
  });

export default function FormattedInputs({ dataPhone }) {

    const [values, setValues] = React.useState({
        phone_number: '(+48) ',
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        dataPhone(values.phone_number);
    }, [values]);


    const [week, setWeek] = React.useState('');
    const handleChangeDay = (event) => {
        setWeek(event.target.value);
    };

    const [time, setTime] = React.useState('');
    const handleChangeTime = (event) => {
        setTime(event.target.value);
    };

    return (
        <Grid container spacing={2} sx={{}}>

            <Grid item xs={12}>
                <FormControl sx={{ width: '100%' }}>
                    {/* <InputLabel htmlFor="formatted-text-mask-input" label="Filled" >Numer telefonu</InputLabel> */}
                    <StyledTextField
                    
                        label="Numer telefonu"
                        variant="filled"
                        value={values.phone_number}
                        onChange={handleChange}
                        name="phone_number"
                        InputProps={{
                            inputComponent: TextMaskCustom
                        }}

                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <StyledFormControl variant="filled" fullWidth>
                    <InputLabel>Dzień tygodnia</InputLabel>
                    <Select
                        value={week}
                        name="call_data"
                        onChange={handleChangeDay} >
                        <MenuItem value='Poniedziałek'>Poniedziałek</MenuItem>
                        <MenuItem value='Wtorek'>Wtorek</MenuItem>
                        <MenuItem value='Środa'>Środa</MenuItem>
                        <MenuItem value='Czwartek'>Czwartek</MenuItem>
                        <MenuItem value='Piątek'>Piątek</MenuItem>
                        <MenuItem value='Sobota'>Sobota</MenuItem>
                        <MenuItem value='Niedziela'>Niedziela</MenuItem>
                    </Select>
                </StyledFormControl>
            </Grid>
            <Grid item xs={6}>
                <StyledFormControl variant="filled" fullWidth>
                    <InputLabel>Godzina</InputLabel>
                    <Select
                        value={time}
                        name="call_time"
                        onChange={handleChangeTime}>
                        <MenuItem value='09:00'>09:00</MenuItem>
                        <MenuItem value='10:00'>10:00</MenuItem>
                        <MenuItem value='11:00'>11:00</MenuItem>
                        <MenuItem value='12:00'>12:00</MenuItem>
                        <MenuItem value='13:00'>13:00</MenuItem>
                        <MenuItem value='14:00'>14:00</MenuItem>
                        <MenuItem value='15:00'>15:00</MenuItem>
                    </Select>
                </StyledFormControl>
            </Grid>
        </Grid>
    );
}