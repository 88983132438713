import './app.css';
import Header from '../components/header';
import Footer from '../components/footer';
import Index from '../pages/index';
import Success from '../pages/success';
import NoMatch from '../pages/noMatch';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route index element={<Index />} />
        <Route path="/success" element={<Success />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
